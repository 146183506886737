export const Icons: Icons[] = [
	{ name: 'account', path: '/assets/icons/account' },
	{ name: 'calendar', path: '/assets/icons/calendar' },
	{ name: 'colors', path: '/assets/icons/colors' },
	{ name: 'flags', path: '/assets/icons/flags' },
	{ name: 'heroicons', path: '/assets/icons/heroicons' },
	{ name: 'logo', path: '/assets/icons/logo' },
	{ name: 'phosphor', path: '/assets/icons/phosphor' },
	{ name: 'sidebar', path: '/assets/icons/sidebar' },
	{ name: 'wefix', path: '/assets/icons/wefix' },
];
