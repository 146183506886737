import '@/styles/app.css';

import NotificationBadge from '@/client/components/NotificationBadge';
import SearchEngine from '@/client/components/SearchEngine';
import ThemeProvider from '@/client/components/ThemeProvider';
import { Elements } from '@/client/content/Elements';
import { Icons } from '@/client/content/Icons';
import { Clock } from '@/client/ui/Clock';
import DatePicker from '@/client/ui/DatePicker';
import DrawerNavigation from '@/client/ui/DrawerNavigation';
import DrawerNavigationDropdown from '@/client/ui/DrawerNavigationDropdown';
import collapse from '@alpinejs/collapse';
import { defineElement } from '@lordicon/element';
import { type SlIcon, registerIconLibrary } from '@shoelace-style/shoelace';
import Alpine from 'alpinejs';
import lottie from 'lottie-web';

(async (): Promise<void> => {
	await Promise.allSettled(Elements);

	Icons.map(({ name, path }) =>
		registerIconLibrary(name, {
			resolver: (name: SlIcon | string) => `${path}/${name}.svg`,
			mutator:
				name === 'heroicons'
					? (svg: SVGElement) => {
							svg.setAttribute('fill', 'none');
							svg.setAttribute('stroke', 'currentColor');
						}
					: undefined,
		}),
	);

	document.body.classList.add('ready');
})();

defineElement(lottie.loadAnimation);

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.data('DatePicker', DatePicker);
Alpine.data('DrawerNavigation', DrawerNavigation);
Alpine.data('DrawerNavigationDropdown', DrawerNavigationDropdown);
Alpine.data('NotificationBadge', NotificationBadge);
Alpine.data('SearchEngine', SearchEngine);
Alpine.data('ThemeProvider', ThemeProvider);
Alpine.store('theme', {
	current: localStorage.getItem('color-theme') || 'light',
});
Alpine.start();

// clock
const clock = new Clock();
clock.run();

if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
		window.location.reload();
	});
}
