import 'number-flow';

export class Clock {
	private hoursFlows: NodeListOf<any>;
	private minutesFlows: NodeListOf<any>;
	private secondsFlows: NodeListOf<any>;

	private lastHours: number;
	private lastMinutes: number;
	private lastSeconds: number;

	private locales: string;

	constructor() {
		this.locales = 'fr-FR';

		this.hoursFlows = document.querySelectorAll('.flow-hours');
		this.minutesFlows = document.querySelectorAll('.flow-minutes');
		this.secondsFlows = document.querySelectorAll('.flow-seconds');

		if (!this.hoursFlows.length || !this.minutesFlows.length || !this.secondsFlows.length) return;

		for (const flow of Array.from(this.hoursFlows)) {
			flow.locales = this.locales;
			flow.format = { minimumIntegerDigits: 2, useGrouping: false };
		}
		for (const flow of Array.from(this.minutesFlows)) {
			flow.locales = this.locales;
			flow.format = { minimumIntegerDigits: 2, useGrouping: false };
		}
		for (const flow of Array.from(this.secondsFlows)) {
			flow.locales = this.locales;
			flow.format = { minimumIntegerDigits: 2, useGrouping: false };
		}

		const now = new Date();
		this.lastHours = now.getHours();
		this.lastMinutes = now.getMinutes();
		this.lastSeconds = now.getSeconds();

		for (const flow of Array.from(this.hoursFlows)) {
			flow.update(this.lastHours);
		}
		for (const flow of Array.from(this.minutesFlows)) {
			flow.update(this.lastMinutes);
		}
		for (const flow of Array.from(this.secondsFlows)) {
			flow.update(this.lastSeconds);
		}

		setInterval(() => this.run(), 1000);
	}

	run() {
		const now = new Date();
		const newHours = now.getHours();
		const newMinutes = now.getMinutes();
		const newSeconds = now.getSeconds();

		if (newSeconds !== this.lastSeconds) {
			for (const flow of Array.from(this.secondsFlows)) {
				flow.update(newSeconds);
			}
			this.lastSeconds = newSeconds;
		}

		if (newMinutes !== this.lastMinutes) {
			for (const flow of Array.from(this.minutesFlows)) {
				flow.update(newMinutes);
			}
			this.lastMinutes = newMinutes;
		}

		if (newHours !== this.lastHours) {
			for (const flow of Array.from(this.hoursFlows)) {
				flow.update(newHours);
			}
			this.lastHours = newHours;
		}
	}
}
