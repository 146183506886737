import type { SlButton, SlDrawer } from '@shoelace-style/shoelace';

const DrawerNavigation = () => {
	return {
		init() {
			const drawerEl: SlDrawer = this.$refs.drawer;
			const drawerOpenEl: SlButton = this.$refs.open;

			drawerOpenEl.addEventListener('click', () => drawerEl.show());
		},
	};
};

export default DrawerNavigation;
