export const Elements = [
	customElements.whenDefined('sl-alert'),
	customElements.whenDefined('sl-avatar'),
	customElements.whenDefined('sl-badge'),
	customElements.whenDefined('sl-button'),
	customElements.whenDefined('sl-breadcrumb'),
	customElements.whenDefined('sl-breadcrumb-item'),
	customElements.whenDefined('sl-card'),
	customElements.whenDefined('sl-checkbox'),
	customElements.whenDefined('sl-copy-button'),
	customElements.whenDefined('sl-details'),
	customElements.whenDefined('sl-dialog'),
	customElements.whenDefined('sl-divider'),
	customElements.whenDefined('sl-dropdown'),
	customElements.whenDefined('sl-icon'),
	customElements.whenDefined('sl-icon-button'),
	customElements.whenDefined('sl-input'),
	customElements.whenDefined('sl-menu'),
	customElements.whenDefined('sl-option'),
	customElements.whenDefined('sl-qr-code'),
	customElements.whenDefined('sl-radio'),
	customElements.whenDefined('sl-radio-button'),
	customElements.whenDefined('sl-radio-group'),
	customElements.whenDefined('sl-select'),
	customElements.whenDefined('sl-spinner'),
	customElements.whenDefined('sl-tab'),
	customElements.whenDefined('sl-tab-group'),
	customElements.whenDefined('sl-tab-panel'),
	customElements.whenDefined('sl-textarea'),
	customElements.whenDefined('sl-tooltip'),
	customElements.whenDefined('sl-visually-hidden'),
];
