const DrawerNavigationDropdown = () => {
	return {
		navigationMenuOpen: false,
		navigationMenu: '',
		navigationMenuCloseDelay: 200,
		navigationMenuCloseTimeout: 300,
		navigationMenuLeave() {
			this.navigationMenuCloseTimeout = setTimeout(() => {
				this.navigationMenuClose();
			}, this.navigationMenuCloseDelay);
		},
		navigationMenuReposition(navigationElement: HTMLElement) {
			this.navigationMenuClearCloseTimeout();

			const navigationDropdown = this.$refs?.navigationDropdown;
			const { offsetLeft, offsetWidth } = navigationElement;

			navigationDropdown!.style.left = `${offsetLeft}px`;
			navigationDropdown!.style.marginLeft = `${offsetWidth / 2}px`;
		},
		navigationMenuClearCloseTimeout() {
			clearTimeout(this.navigationMenuCloseTimeout);
		},
		navigationMenuClose() {
			this.navigationMenuOpen = false;
			this.navigationMenu = '';
		},
	};
};

export default DrawerNavigationDropdown;
