import fetch from '@/client/api/Fetcher';

const NotificationBadge = () => {
	return {
		async init() {
			const badge = this.$refs.NotificationBadgeCount;

			try {
				const { data } = await fetch.Get('/rendez-vous/count', {
					responseType: 'json',
				});
				if (data.count > 0) badge.innerHTML = data.count;
				badge.classList.toggle('hidden', data.count <= 0);
			} catch (error) {
				if (env === 'development') {
					console.error(error);
				}

				badge.classList.add('hidden');
			}
		},
	};
};

export default NotificationBadge;
