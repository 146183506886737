import Alpine from 'alpinejs';

const ThemeProvider = () => {
	return {
		document: document.documentElement,
		schema: '(prefers-color-scheme: dark)',
		init() {
			const savedTheme = localStorage.getItem('color-theme');
			const prefersDark = window.matchMedia(this.schema).matches;
			const initialTheme = savedTheme || (prefersDark ? 'dark' : 'light');

			this.applyTheme(initialTheme);

			window.addEventListener('storage', (event: StorageEvent) => {
				if (event.key === 'color-theme') {
					const newTheme = event.newValue || 'light';
					this.applyTheme(newTheme);
				}
			});
		},
		toggleTheme() {
			const isDark = this.document.classList.contains('dark');
			const currentTheme = isDark ? 'dark' : 'light';
			const newTheme = currentTheme === 'light' ? 'dark' : 'light';
			this.applyTheme(newTheme);
		},
		applyTheme(theme: string) {
			const oppositeTheme = theme === 'light' ? 'dark' : 'light';
			const iconName = theme === 'light' ? 'moon' : 'sun';

			this.document.classList.remove(oppositeTheme);
			this.document.classList.add(theme);
			this.document.setAttribute('style', `color-scheme: ${theme};`);

			if (this.$refs?.theme) {
				this.$refs.theme.setAttribute('name', iconName);
			}

			localStorage.setItem('color-theme', theme);
			(Alpine.store('theme') as { current: string }).current = theme;
		},
	};
};

export default ThemeProvider;
