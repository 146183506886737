import 'number-flow';
import fetch from '@/client/api/Fetcher';
// import initDB from '@/client/db';
// import { addElement, editElement, getElement, removeElement } from '@/client/db/Action';
import { PromiseWait } from '@/client/hooks/PromiseWait';
import type { SlInputEvent } from '@shoelace-style/shoelace';
import { SlInput } from '@shoelace-style/shoelace';
import { EMPTY, from, fromEvent } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';

const SearchEngine = () => {
	return {
		url_results: '/search',
		animationDuration: 150,
		searchDebounce: 200,
		init() {
			// initDB();

			const { label, input, icon, spinner, escape, results, searchDialog, openSearchDialog } =
				this.$refs;
			this.label = label;
			this.input = input;
			this.icon = icon;
			this.spinner = spinner;
			this.escape = escape;
			this.results = results;
			this.searchDialog = searchDialog;
			this.openSearchDialog = openSearchDialog;

			if (!this.searchDialog) return;
			openSearchDialog.addEventListener('click', async () => {
				this.searchDialog.show();

				await PromiseWait(500);
				this.label.classList.remove('hidden');
				this.label.classList.add('motion-preset-slide-up');
				await PromiseWait(500);
				this.input.focus();
				await PromiseWait(500);
				this.escape.classList.remove('hidden');
				this.escape.classList.add('motion-preset-bounce');
			});

			searchDialog.addEventListener('sl-hide', () => {
				this.label.classList.add('hidden');
				this.label.classList.remove('motion-preset-slide-up');
				this.escape.classList.add('hidden');
				this.escape.classList.remove('motion-preset-bounce');
			});

			// show search dialog when slash (/) character or CMD/CTRL + K is pressed
			const keydown$ = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
				filter((event) => {
					const isSlash = event.key === '/';
					const isCtrlK = (event.metaKey || event.ctrlKey) && event.key === 'k';
					const inInputOrTextarea = event.composedPath().some((el) => {
						return (
							el instanceof HTMLElement && ['input', 'textarea'].includes(el.tagName.toLowerCase())
						);
					});

					return (isSlash || isCtrlK) && !inInputOrTextarea;
				}),
				tap((event) => {
					event.preventDefault();
					this.searchDialog.show();
				}),
			);

			keydown$.subscribe();

			// fetch data from a term typed by the user
			this.getSearchResults();
		},
		getSearchResults() {
			const input$ = fromEvent<SlInputEvent>(this.input, 'sl-input').pipe(
				filter((event: SlInputEvent) => event.target instanceof SlInput),
				debounceTime(300),
				tap(() => {
					this.icon.classList.add('hidden');
					this.spinner.classList.remove('hidden');
				}),
				switchMap((event: SlInputEvent) => {
					const term = (event.target as HTMLInputElement).value;
					if (term === '') {
						this.icon.classList.remove('hidden');
						this.spinner.classList.add('hidden');

						this.results.innerHTML = '';
						this.results.classList.add('hidden');

						return EMPTY;
					}

					return from(
						fetch.Post(this.url_results, {
							term,
						}),
					);
				}),
			);

			input$.subscribe({
				next: async ({ data }) => {
					this.icon.classList.remove('hidden');
					this.spinner.classList.add('hidden');

					this.results.innerHTML = data.template;
					await this.$nextTick();

					const currentValue = this.input.value;
					if (currentValue === '') {
						this.results.classList.add('hidden');
					} else {
						this.results.classList.remove('hidden');
					}

					let count = 0;
					this.$refs.count.update(count);
					const timer = setInterval(() => {
						if (count >= data.response.count) {
							clearInterval(timer);
						} else {
							count++;
							this.$refs.count.update(count);
						}
					}, 10);
				},
				error: (error) => {
					if (env === 'development') {
						console.error(error);
					}

					this.icon.classList.remove('hidden');
					this.spinner.classList.add('hidden');

					this.results.innerHTML = error.response.data.template;
					this.results.classList.remove('hidden');
				},
			});
		},
	};
};

export default SearchEngine;
